import { useLinks } from '@bluheadless/ui-logic/src/hooks/useLinks'
import {
	Accordion,
	CtasWrapper,
	Description,
	Subtitle,
	TextContainer,
	Title,
	TitleWrapper,
} from './accordion-wrapper.styled'
import Video from '@bluheadless/ui/src/particles/video'
import Link from '@bluheadless/ui/src/molecules/link'
import Typography from '@bluheadless/ui/src/atoms/typography'
import Image from '@bluheadless/ui/src/particles/image'
import { isFullURL } from '@bluheadless/ui-logic/src/utils'
import { mediaUrl } from '@bluheadless/ui-logic/src/assets'
import IconHeadArrowDown from '@bluheadless/ui/src/atoms/icons/arrowhead-down-icon'

const AccordionWrapper = ({
	bgImage,
	content,
	ctas,
	expanded,
	handleChange,
	index,
	subtitle,
	subtitleTag,
	title,
	titleTag,
	video,
}) => {
	const links = useLinks([
		{ type: ctas[0]?.linkType, href: ctas[0]?.link },
		{ type: ctas[1]?.linkType, href: ctas[1]?.link },
		{ type: ctas[2]?.linkType, href: ctas[2]?.link },
	])

	return (
		<Accordion
			content={
				<>
					<Video
						{...video}
						autoplay={false}
						controls={false}
						playing={expanded === 'panel' + index}
						startOnEveryInView={false}
					/>

					<TextContainer>
						<Subtitle color="primary" component={subtitleTag}>
							{subtitle}
						</Subtitle>

						<Description content={content} />

						{links?.length ? (
							<CtasWrapper direction="row" spacing={5}>
								{links.map((value, i) => (
									<Link href={value} key={i}>
										<Typography component="h3" variant="body2">
											{ctas[i]?.text}
										</Typography>
									</Link>
								))}
							</CtasWrapper>
						) : null}
					</TextContainer>
				</>
			}
			expanded={expanded === 'panel' + index}
			expandIcon={null}
			key={index}
			onChange={handleChange('panel' + index)}
			title={
				<>
					<Image {...bgImage} src={isFullURL(bgImage.src) ? bgImage.src : mediaUrl(bgImage.src)} alt={title}></Image>

					<TitleWrapper>
						<Title component={titleTag}>{title}</Title>

						<IconHeadArrowDown fontSize="medium" />
					</TitleWrapper>
				</>
			}
		/>
	)
}

export default AccordionWrapper
