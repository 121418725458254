import { styled } from '@mui/system'
import RichContent from '@bluheadless/ui/src/particles/rich-content'
import Typography from '@bluheadless/ui/src/atoms/typography'
import { Stack as MuiStack } from '@mui/material'

const Title = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(25)};
	font-weight: ${({ theme }) => theme.typography.fontWeightBold};
	letter-spacing: -0.5px;
	line-height: 1;
`

const TextContainer = styled('div')`
	padding: ${({ theme }) => theme.spacing(2.4, 5, 5)};
`

const Subtitle = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(40)};
	font-stretch: ${({ theme }) => theme.typography.fontStretchCondensed};
	font-weight: ${({ theme }) => theme.typography.fontWeightCondensedBlack};
	line-height: 1;
`

const Description = styled(RichContent)`
	color: ${({ theme }) => theme.palette.primary.main};
	padding-top: ${({ theme }) => theme.spacing(1.25)};
`

const CtasWrapper = styled(MuiStack)`
	padding-top: ${({ theme }) => theme.spacing(8)};
`

export { CtasWrapper, Description, Subtitle, TextContainer, Title }
