import { styled } from '@mui/system'
import Typography from '@bluheadless/ui/src/atoms/typography'
import RichContent from '@bluheadless/ui/src/particles/rich-content'
import BHAccordion from '@bluheadless/ui/src/molecules/accordion'
import { Stack as MuiStack } from '@mui/material'

const Accordion = styled(BHAccordion)`
	border: 0;
	width: 100%;
	&:first-of-type {
		border-top: 0;
	}
	.MuiAccordionSummary-root {
		padding: 0;
		transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		&.Mui-expanded {
			.MuiAccordionSummary-content > div:first-of-type:not(:last-child):after {
				background-color: ${({ theme }) => theme.palette.primary.main};
			}
			.MuiSvgIcon-root {
				transform: rotate(180deg);
			}
		}
	}
	.MuiAccordionSummary-content {
		align-items: center;
		color: ${({ theme }) => theme.palette.common.white};
		font-stretch: ${({ theme }) => theme.typography.fontStretchCondensed};
		width: 100%;
		> div:first-of-type:not(:last-child):after {
			background-color: rgba(0, 0, 0, 0.54);
			content: '';
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			width: 100%;
		}
	}
	.MuiCollapse-root {
		background-color: #d6d8da;
	}
	.MuiAccordionDetails-root {
		padding: 0;
	}
`

const TitleWrapper = styled('div')`
	align-items: center;
	display: flex;
	position: absolute;
	.MuiSvgIcon-root {
		font-size: ${({ theme }) => theme.typography.pxToRem(17)};
		transform: rotate(0deg);
		transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	}
`

const Title = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(25)};
	font-weight: ${({ theme }) => theme.typography.fontWeightBold};
	letter-spacing: -0.5px;
	line-height: 1;
	padding: ${({ theme }) => theme.spacing(0, 1.25, 0, 3)};
`

const TextContainer = styled('div')`
	padding: ${({ theme }) => theme.spacing(2.4, 5, 5)};
`

const Subtitle = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(40)};
	font-stretch: ${({ theme }) => theme.typography.fontStretchCondensed};
	font-weight: ${({ theme }) => theme.typography.fontWeightCondensedBlack};
	line-height: 1;
`

const Description = styled(RichContent)`
	color: ${({ theme }) => theme.palette.primary.main};
	padding-top: ${({ theme }) => theme.spacing(1.25)};
`

const CtasWrapper = styled(MuiStack)`
	padding-top: ${({ theme }) => theme.spacing(8)};
`

export { Accordion, CtasWrapper, Description, Subtitle, TextContainer, Title, TitleWrapper }
